import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import styled from 'styled-components';

import arrowRight from '../../assets/arrow-right.svg';
import whiteArrow from '../../assets/white-arrow.svg';
import LinkWithSvg from '../components/link-wth-svg';

const CarouselContainer = styled.div`
    position: relative;

    .carousel.carousel-slider .slide {
        background: ${props => props.theme.white};
    }

    .carousel.carousel-slider .slide .slide-content {
        opacity:0;transition: opacity 0.9s ease-out;
    }

    .carousel.carousel-slider .slide.selected .slide-content {
        opacity:1;
        transition: opacity 0.9s ease-in;
    }

    .carousel.carousel-slider .control-dots {
        height: 40px;
        margin: 0;
        top: 39.5%;
    }

    .carousel.carousel-slider .control-dots .dot {
        box-shadow: none;
        opacity: 1;
        outline: none;
        margin: 16px 8px;
    }

    .carousel.carousel-slider .control-dots .dot.selected {
        background: ${props => props.theme.main};
        outline: none;
        -webkit-tap-highlight-color: transparent;
    }

    .carousel .control-arrow, .carousel.carousel-slider .control-arrow {
        background: transparent;
        height: 250px;
        opacity: 1;
        outline: none;
        -webkit-tap-highlight-color: transparent;
    }
    
    .carousel.carousel-slider .control-arrow:hover {
        background: transparent;
    }
    
    .carousel .control-arrow:before, .carousel.carousel-slider .control-arrow:before {
        margin: 0 10px;
        border: none;
    }

    .carousel .control-arrow:before, 
    .carousel.carousel-slider .control-arrow:before, 
    .carousel .control-arrow:before, 
    .carousel.carousel-slider 
    .control-arrow:before {
        background: url(${whiteArrow}) no-repeat center;
        height: 25px;
        width: 20px;
    }

    .carousel .control-arrow.control-next:before {
        transform: rotate(180deg);
    }
    
    @media screen and (min-width: 575px) {
        .carousel .control-arrow, .carousel.carousel-slider .control-arrow {
            height: 350px;
        }
        .carousel.carousel-slider .control-dots {
            top: 44.9%;
        } 
    }
    
    @media screen and (min-width: 720px) {
        .carousel.carousel-slider .control-dots {
            display: none;
        }
        .carousel .control-arrow, .carousel.carousel-slider .control-arrow {
            display: none;
        }
    }
`;

const Slide = styled.div`
    background: ${props => props.theme.white};
    display: flex;
    flex-direction: column;
`;

const SlideContent = styled.div`
    display: flex;
    flex-direction: row;
    height: 260px;
    padding: 31px ${props => props.theme.paddings['mobile']} 38px ${props => props.theme.paddings['mobile']};
    
    @media screen and (min-width: 575px) {
        height: 340px;
        padding: 40px ${props => props.theme.paddings['575']} 50px ${props => props.theme.paddings['575']};
    }
        
    @media screen and (min-width: 720px) {
        height: 265px;
        padding: 25px ${props => props.theme.paddings['720']} 50px ${props => props.theme.paddings['720']};
    }
    
    @media screen and (min-width: 1024px) {
        height: 310px;
        padding: 37px ${props => props.theme.paddings['1024']} 60px ${props => props.theme.paddings['1024']};
    }
    
    @media screen and (min-width: 1440px) {
        height: 410px;
        padding: 78px ${props => props.theme.paddings['1440']} 100px  ${props => props.theme.paddings['1440']};
    }
`;

const BigImage = styled.img`
    height: 250px;
    object-fit: cover;
    
    @media screen and (min-width: 574px) {
        height: 350px;
    }
    @media screen and (min-width: 720px) {
        height: 325px;
    }

    @media screen and (min-width: 1024px) {
        height: 400px;
    }

    @media screen and (min-width: 1440px) {
        height: 500px;
    }
`;

const SlideContentLeft = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 100%;    
    
    div.link-with-svg {
        margin-top: auto;
    }
`;

const SlideContentRight = styled.div`
    display: none;
    flex-direction: row;
    position: absolute;
    right: 7.8%;
    z-index: 11;
    
    span {
        white-space: nowrap;
        font-size: 14px;
        font-weight: bold;
        margin: 0 2px 0 2px;
    }
    
    @media screen and (min-width: 720px) {
        display: flex;
        align-items: center;
        height: 21px;
        right: 80px;
        top: 368px;
    }
    
    @media screen and (min-width: 1024px) {
        top: 457px;
        width: 82px;
        span {
            font-size: 20px;
        }
    }
    
    @media screen and (min-width: 1440px) {
        top: 586px;
        span {
            font-size: 20px;
        }
    }
`;

const ArrowImage = styled.img`
    align-items: center;
    cursor: pointer;
    display: flex; 
    pointer-events: auto; 
    
    @media screen and (min-width: 720px) {
             
    }
    
    @media screen and (min-width: 1024px) {
        height: 20px;
        max-width: 13px;  
    }
`;

const SlideTitle = styled.h2`
    width: 100%;
    margin-bottom: 11px;
    max-width: 275px;

    @media screen and (min-width: 575px) {
        margin-bottom: 20px;
        max-width: 430px;
    }
    
    @media screen and (min-width: 720px) {
        margin-bottom: 0;
        max-width: 455px;
    }

    @media screen and (min-width: 1024px) {
        margin-bottom: 9px;
    }   
    
    @media screen and (min-width: 1440px) {
        margin-bottom: 22px;
        max-width: 87.33%;
    }       
`;

const SlideDescription = styled.p` 
    max-width: 275px;
    @media screen and (min-width: 575px) {
        max-width: 430px;
    }
    @media screen and (min-width: 720px) {
        max-width: 455px;
    }
    @media screen and (min-width: 1024px) {
        max-width: 646px;
    }
    @media screen and (min-width: 1440px) {
        max-width: 87.33%;
    }
`;

let firstClientX; let clientX;

const preventTouch = e => {
    const minValue = 5; // threshold

    clientX = e.touches[0].clientX - firstClientX;

    // Vertical scrolling does not work when you start swiping horizontally.
    if ((Math.abs(clientX) > minValue) && e.cancelable) {
        e.preventDefault();

        e.returnValue = false;
        return false;
    } else {
        if (e.cancelable) {
            e.stopPropagation();
        }
    }
};

const touchStart = e => {
    firstClientX = e.touches[0].clientX;
};

const Slider = slides => {
    const { data } = slides;
    const [selectedItem, setSelectedItem] = useState(0);
    const containerRef = React.createRef();
    // stop autoplay when hovering the arrows
    const [allowAutoPlay, setAllowAutoPlay] = useState(true);

    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.addEventListener('touchstart', touchStart);
            containerRef.current.addEventListener('touchmove', preventTouch, {
                passive: false
            });
        }
        return () => {
            if (containerRef.current) {
                containerRef.current.removeEventListener('touchstart', touchStart);
                containerRef.current.removeEventListener('touchmove', preventTouch, {
                    passive: false
                });
            }
        };
    });

    const handleClickItem = (index, item) => {
        if (index !== item) {
            setSelectedItem(index);
        }
        setSelectedItem(item);
    };

    const onChange = e => {
        setSelectedItem(e);
    };

    return (
        <CarouselContainer ref={containerRef}>
            <Carousel showArrows={true} showThumbs={false} stopOnHover={true} showIndicators={true}
                selectedItem={selectedItem} showStatus={false} verticalSwipe='natural' infiniteLoop={true}
                interval={4000} autoPlay={allowAutoPlay} onChange={e => onChange(e)}>
                { data.map((slide, s) => {
                    const { slide_description, slide_image, slide_link, slide_title } = slide;
                    return <Slide key={s}>
                        <BigImage src={slide_image.url} />
                        <SlideContent className='slide-content'>
                            <SlideContentLeft showslide={selectedItem === s} className='carousel'>
                                <SlideTitle >{slide_title[0].text}</SlideTitle>
                                <SlideDescription>{slide_description[0].text}</SlideDescription>
                                <LinkWithSvg text={slide_link[0].text}
                                    destination={`/about/${slide_link[0].spans[0].data.slug === 'about' ?
                                        'manifesto' : slide_link[0].spans[0].data.uid}`} />
                            </SlideContentLeft>
                        </SlideContent>
                    </Slide>;
                })}
            </Carousel>
            { carouselButtons(data.length, selectedItem, handleClickItem, setAllowAutoPlay) }
        </CarouselContainer>
    );
};

const carouselButtons = (nrOfSlices, selectedItem, handleClickItem, setAllowAutoPlay) => {
    let pageIndicator = selectedItem + 1;
    if (selectedItem === 4 || selectedItem === 0) {
        pageIndicator = 1;
    }

    if (selectedItem === -1) {
        pageIndicator = 4;
    }

    return (
        <SlideContentRight onMouseEnter={() => setAllowAutoPlay(false)} onMouseLeave={() => setAllowAutoPlay(true)}>
            <ArrowImage src={arrowRight} style={{ transform: 'rotate(180deg)', marginBottom: '-2px' }}
                onClick={() => handleClickItem((selectedItem - 1), (selectedItem === -1 ?
                    2 : selectedItem - 1))} />
            <span>{`${pageIndicator} / ${nrOfSlices}`}</span>
            <ArrowImage src={arrowRight}
                onClick={() => handleClickItem(selectedItem + 1, (selectedItem === 4 ?
                    1 : selectedItem + 1))} />
        </SlideContentRight>
    );
};

export default Slider;
