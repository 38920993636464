import React, { useState, useLayoutEffect } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Slice from '../components/slice';
import Slider from '../components/carousel';
import BottomArrowUp from '../components/bottom-arrow-up-mobile';
import Border from '../components/border';

// import { RichText } from 'prismic-reactjs';
// import Orbital from '../components/orbital';

// const MainContent = styled.div`
//     /* position: absolute; */
//     /* left: 50%; */
//     /* top: 50%; */
//
//     width: 430px;
//     height: 250px;
//     background-color: ${props => props.theme.main};
//     color: white;
//     padding: 30px;
//
//     /* padding-top: 20px; */
//     & p:first-child {
//         margin-bottom: 0.7rem;
//     }
//     & p {
//         font-size: 18px;
//         line-height: 30px;
//     }
//     & a {
//         display: block;
//         color: ${props => props.theme.white};
//     }
// `;

const Section = styled.section`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    height: auto !important;

    & .final-section {
        flex-direction: column;

        .link-with-svg {
            // changed because bug nr 102 -- vertical spacing
            margin-top: auto;
            // margin-top: 30px;
        }
        & > div {
            height: auto;
        }
        
        @media (max-width: 575px) {
            & > div:first-child {
                > div {
                    min-height: 250px;
                    max-height: 250px;
                } 
            }
        }
        
        & > * > a {
            padding-top: 280px;
            @media screen and (min-width: 575px) {
                 padding-top: 400px;  
            }
            @media screen and (min-width: 1024px) {
                padding-top: 500px;
            }

            @media (min-width: 1440px) {
                padding-top: 600px;
            }
        }
        p {
             max-width: 275px!important;       
        }
        
        @media (min-width: 575px) {
            p {
                max-width: 430px!important;       
            }
        }
        
        @media (min-width: 575px) and (max-width: 720px) {
            & > div:first-child {
                > div {
                    min-height: 350px;
                    max-height: 350px;
                } 
            }
        }

        @media (min-width: 720px) {

            & > div > a > div {
                display: none;
            }

            & > *:last-child a {
                position: relative;
                &:after {
                    content: '';
                    position: absolute;
                    left: 50%;
                    top: 0;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 30px 30px 0 30px;
                    border-color: ${props => props.theme.white} transparent transparent transparent;
                    transform: translateX(-50%);
                }
            }
            p {
                 max-width: 455px!important;       
            }
        }
        @media (min-width: 1024px) {
            .link-with-svg {
                margin: 20px auto 27px 0;  
            }
            p {
                max-width: 87.33%!important;
            }
          
        }
    }
`;

const Spacer = styled.div`
    padding: 35px 30px 26px 30px;
    h2 {   
        margin-bottom: 15px;
    } 
    
    @media (min-width: 575px) {
        padding: 40px;
        h2 {   
            margin-bottom: 13px;
        } 
    }
    
    @media (min-width: 720px) {
        padding: 24px 40px 40px 40px; 
    }
    
    @media (min-width: 1024px) {
        padding: 37px 64px 40px 64px; 
        h2 {   
            margin-bottom: 10px;
        } 
    }
    
    @media (min-width: 1440px) {
        padding: 73px 110px 94px 110px;
        h2 {   
            margin-bottom: 20px;
        } 
    }
`;
const ScrollElement = styled.div`
    position: absolute;
    top: 600px;
    @media (min-width: 575px) {
        top: 700px;
    }
    @media (min-width: 720px) {
        top: 600px;
    }
    @media (min-width: 1024px) {
        top: 740px;
    }
    @media (min-width: 1440px) {
        top: 970px;
    }
`;
const scrollToSection = id => {
    const element = typeof document !== 'undefined' && document.getElementById(id);
    element.scrollIntoView({ behavior: 'smooth', block: 'start' });

};
const IndexPage = data => {
    const { title, description, slider, body } = data.data.prismic.node;
    const pageUrl = typeof window !== 'undefined' && window.location.href;
    const [doScroll, setDoScroll] = useState(false);
    const handleSlices = slices => {
        if (!slices) {
            return null;
        }
        return slices.map((slice, index) => {
            return <Slice key={index} data={slice} />;
        });
    };
    let descIndex = '';
    description.map(item => descIndex += item.text);

    useLayoutEffect(() => {
        const localStorageScrollItem = typeof localStorage !== 'undefined' && localStorage.getItem('scrollAfterCarousel');
        if (localStorageScrollItem) {
            setTimeout(() => {
                scrollToSection('our-work-matches-up-with-the-real-world');
                setDoScroll(true);
            }, 100);
        }
        return () => {
            localStorage.removeItem('scrollAfterCarousel');
            setDoScroll(false);
        };
    }, [doScroll]);

    return (
        <Layout>
            <SEO title={title} canonical={pageUrl} description={descIndex}/>

            <Slider data={slider} />

            <Border />

            <Spacer className="no-bottom-space no-top-space" />

            <Section> {handleSlices(body)}</Section>

            <ScrollElement id='our-work-matches-up-with-the-real-world'/>

            <BottomArrowUp />
        </Layout>
    );
};


export const query = graphql`
    {
        prismic {
            node: page(uid: "home", lang: "en-us") {
                description
                title
                slider {
                    slide_description
                    slide_link
                    slide_title
                    slide_image
                }
                body {
                    ... on PRISMIC_PageBodyHome_image_and_text {
                        type
                        label
                        primary {
                            case_link
                            image_side
                            main_image
                            small_image
                            section_title
                        }
                        fields {
                            tags
                        }
                    }
                    ... on PRISMIC_PageBodyNext_item_cta {
                        type
                        label
                        primary {
                            text
                            background_image
                            cta_text
                            next_item {
                                ... on PRISMIC_Product {
                                    title
                                    _meta {
                                        uid
                                    }
                                }
                            }
                        }
                    }
                    ... on PRISMIC_PageBodyText {
                        type
                        label
                        primary {
                          text
                        }
                    }
                    ... on PRISMIC_PageBodyDotted_border {
                        type
                        label
                        primary {
                            hide_desktop
                        }
                    }
                }
            }
        }
    }
`;

export default IndexPage;
